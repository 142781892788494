import React from "react";
import CanvasDraw from "react-canvas-draw";
import { Link } from "react-router-dom";

export default function Admin() {
    let loadableCanvas;
  
  return (
    <div className="home">
      <div className="container">
        <div className="page-header text-center m-5">
          <h1>
            Signature Pad Result | React JS By <small> Qureshi Taha</small>
          </h1>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h1>Example Signed Contract</h1>
              </div>
              <div className="col-md-12">
                <div id="contract-area">
                <p>
                    The classic latin passage that just never gets old, enjoy as
                    much (or as little) lorem ipsum as you can handle with our
                    easy to use filler text generator. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur.
                  </p>
                  <p>
                    The classic latin passage that just never gets old, enjoy as
                    much (or as little) lorem ipsum as you can handle with our
                    easy to use filler text generator. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur.
                  </p>
                  <p>
                    The classic latin passage that just never gets old, enjoy as
                    much (or as little) lorem ipsum as you can handle with our
                    easy to use filler text generator. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu
                    fugiat nulla pariatur.
                  </p>
                </div>
              </div>
            </div>
            <div id="sign-widget">
              <CanvasDraw
                disabled
                hideGrid
                ref={(canvasDraw) => (loadableCanvas = canvasDraw)}
                saveData={localStorage.getItem("savedDrawing")}
                canvasHeight={200}
              />
            </div>
          </div>

          <div className="col-md-5">
            <h1>What Is This?</h1>
            <p>
              This is an example using a signature widget with react. When the
              user signs using their cursor or touchpad and then submits the
              signature, a base64 image is created and added to the bottom of
              the example contract.
            </p>
            <div className="alert alert-primary">
              <p>
                <b>Tip: </b>
                For adding your signature go <Link to={"/"}>Here.</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
