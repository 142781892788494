import React from "react";
import { Link } from "react-router-dom";

export default function PageNotFound() {
  return (
    <section
      className="w-100 bg-f2  p-5 position-relative"
      style={{ height: "100vh" }}
    >
      <div className="container h-100 p-5 ">
        <div className="d-flex align-items-center justify-content-center h-100 ">
          <div className="d-flex flex-column w-75 row">
            <div className="align-items-center justify-content-center text-center">
              <h1 className="branding1 fw-bolder">404</h1>
              <h6 className="color-grey">
                The page you requested was not found.
              </h6>
              <div className="pt-5">
                <Link to={"/"} className="btn btn-link">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
