import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Admin from "./Pages/admin";
import Home from "./Pages/home";
import PageNotFound from "./Pages/PageNotFound";

export default function Website() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/admin" element={<Admin />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
